/*jshint esversion: 6 */

(function(global, factory) {
  'use strict';
  if (typeof define === 'function' && define.amd) {
    define(['jquery'], function($) {
      return factory($, global, global.document);
    });
  } else if (typeof exports === "object" && exports) {
    module.exports = factory(require('jquery'), global, global.document);
  } else {
    factory(jQuery, global, global.document);
  }
})(typeof window !== 'undefined' ? window : this, function($, window, document, undefined) {
  'use strict';

  // -- Name is used to keep jQUery plugin template portable
  const pluginName = 'modal';

  // -- Globals (shared across all plugin instances)
  const defaultOptions = {
  };

  const $window = $(window);
  const $document = $(document);

  const p = {}; p[pluginName] = class {
    constructor(el, opts) {
      this.el = el;
      this.opts = $.extend({}, defaultOptions, opts);

      this._defaultOptions = defaultOptions;
      this.$el = $(this.el);

      this.modalId = this.$el.data('modalId');

      this.$el.find('.close-button, .background').on('click', event => {
        this.close();
      });

      $document.keyup(event => {
        if (event.keyCode === 27) this.close();
      });

      this.initLinks();

    }

    initLinks() {
      $(`[data-modal-target-id='${this.modalId}']`).on('click', event => {
        event.preventDefault();
        this.open();
      });
    }

    open() {
      this.initIframe();

      this.$el.fadeIn('fast');
      this.scrollPosition = $document.scrollTop();
      $('html, body').css({overflow: 'hidden', 'overscroll-behavior-y': 'none'});

    }

    close() {
      this.$el.fadeOut('fast');
      $('html, body').css({overflow: '', 'overscroll-behavior-y': ''});
      $document.scrollTop(this.scrollPosition);
    }

    initIframe() {
      const $iframe = this.$el.find('iframe');
      const dataSrc = $iframe.data('src');

      if (dataSrc) {
        $iframe.attr('src', dataSrc);
      }
    }

  };

  $.fn[pluginName] = function(options) {
    return this.each(function () {
      if (!$.data(this, 'plugin_'+ pluginName)) {
        $.data(this, 'plugin_'+ pluginName, new p[pluginName](this, options));
      }
    });
  };
});
