function isInViewport (elem) {
  const bounding = elem.getBoundingClientRect();
  return (
      bounding.top >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}


function init() {

  let $videos = $('.video-with-text video');

  for (const video of $videos) {
    const $video = $(video);

    $video.on('click', () => {
      $video[0].play();
    });

    if (isInViewport($video[0])) {
      if (!$video.hasClass('played')) {
        $video[0].play();
      }
      $video.addClass('played');
    }

    $(window).on('scroll', event => {
      if (isInViewport($video[0])) {
        if (!$video.hasClass('played')) {
          $video[0].play();
        }
        $video.addClass('played');
      } else {
      }
    });
  }


}

export default init;