import $ from 'jquery';
window.$ = $;
import videoInViewport from './video-in-viewport';
import productTable from './product-table';
import modal from './jquery.modal';
import faq from './faq';
import cookieConsent from 'cookieconsent';
import cookieConsentCss from 'cookieconsent/build/cookieconsent.min.css';

$(document).ready(() => {
  videoInViewport();
  productTable();
  faq();


  $('.modal').modal();
});