function init() {

  const $table = $('.product-table table');

  $('tbody tr').each((index, tr) => {

    const $tr = $(tr);

    if ($tr.find('td:nth-child(2) .check-mark').length === 1) {
      $tr.find('td:nth-child(2)').html($tr.find('td:nth-child(2)').html() + '<div class="xs">' + $tr.find('td:nth-child(1)').html() + '</div>');
    }

    if ($tr.find('td:nth-child(3) .check-mark').length === 1) {
      $tr.find('td:nth-child(3)').html($tr.find('td:nth-child(3)').html() + '<div class="xs">' + $tr.find('td:nth-child(1)').html() + '</div>');
    }

  });



}

export default init;