function init() {
  const $faqs = $('.faq');

  console.log($faqs);

  $faqs.each((index, elm) => {
    let $faq = $(elm);

    console.log('each');

    $faq.find('.faq__headline').on('click', event => {


      if ($faq.hasClass('open')) {
        $faq.removeClass('open');
        $faq.find('.faq__text').slideUp('fast');

      } else {
        $faq.addClass('open');
        $faq.find('.faq__text').slideDown('fast');
      }
    });
  });
}

export default init;